import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaymentContext } from '../../../App';
import { ESimplePayCallbackTypes } from '../../../enums/simplepay-callback-types.enum';
import PaymentCancelledModal from '../PaymentCancelledModal/PaymentCancelledModal';
import PaymentFailedModal from '../PaymentFailedModal/PaymentFailedModal';
import PaymentPendingModal from '../PaymentPendingModal/PaymentPendingModal';
import PaymentSucceedModal from '../PaymentSucceedModal/PaymentSucceedModal';
import PaymentTimeoutModal from '../PaymentTimeoutModal/PaymentTimeoutModal';

type PaymentModalRootProps = {};

function PaymentModalRoot(props: PaymentModalRootProps) {
  const paymentContext = useContext(PaymentContext);
  const navigate = useNavigate();

  const closeHandler = () => {
    paymentContext.setPaymentType(ESimplePayCallbackTypes.NONE);
    paymentContext.setTransactionCode('');
    navigate('/home', { replace: true });
  };

  return (
    <>
      <PaymentSucceedModal
        isVisible={paymentContext.paymentType === ESimplePayCallbackTypes.FINISHED}
        closeModalHandler={closeHandler}
        transactionCode={paymentContext.transactionCode}
      />
      <PaymentFailedModal
        isVisible={
          paymentContext.paymentType === ESimplePayCallbackTypes.FAIL ||
          paymentContext.paymentType === ESimplePayCallbackTypes.NOTAUTHORIZED
        }
        closeModalHandler={closeHandler}
        transactionCode={paymentContext.transactionCode}
      />
      <PaymentCancelledModal isVisible={false} closeModalHandler={closeHandler} />
      <PaymentTimeoutModal isVisible={false} closeModalHandler={closeHandler} />
      <PaymentPendingModal
        isVisible={paymentContext.paymentType === ESimplePayCallbackTypes.PENDING}
        closeModalHandler={closeHandler}
      />
    </>
  );
}

export default PaymentModalRoot;
