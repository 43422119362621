import './HomePage.scss';

import { Grid } from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { PaymentContext } from '../../App';
import { ReactComponent as FoodMenuIcon } from '../../assets/icons/menu-icon.svg';
import { ReactComponent as SyncIcon } from '../../assets/icons/sync-icon.svg';
import { ReactComponent as TopUpIcon } from '../../assets/icons/top-up-icon.svg';
import CustomPriceModal from '../../components/Modals/CustomPriceModal/CustomPriceModal';
import PaymentModalRoot from '../../components/Modals/PaymendModalRoot/PaymentModalRoot';
import PriceOptionConfirmModal from '../../components/Modals/PriceOptionConfirmModal/PriceOptionConfirmModal';
import PriceOptionsModal from '../../components/Modals/PriceOptionsModal/PriceOptionsModal';
import { SnackbarContext } from '../../components/SnackbarAlert/SnackbarAlert';
import { ESimplePayCallbackTypes } from '../../enums/simplepay-callback-types.enum';
import { startSimplepayTransaction } from '../../services/simplepay.service';
import { getCreditInformation } from '../../services/wallet.service';

interface HomePageProps {}

const HomePage: FunctionComponent<HomePageProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const [creditInformation, setCreditInformation] = useState('');
  const [isPriceOptionsModalVisible, setIsPriceOptionsModalVisible] = useState(false);
  const [isPriceOptionConfirmModalVisible, setIsPriceOptionConfirmModalVisible] = useState(false);
  const [isCustomPriceModalVisible, setIsCustomPriceModalVisible] = useState(false);
  const [priceOption, setPriceOption] = useState<number | null>(null);
  const [searchParams] = useSearchParams();
  const paymentContext = useContext(PaymentContext);
  const location = useLocation();

  const priceOptionSelectedHandler = (price?: 'custom' | number | null) => {
    setIsPriceOptionsModalVisible(false);
    setIsCustomPriceModalVisible(false);
    if (price) {
      setTimeout(() => {
        if (price === 'custom') {
          setIsCustomPriceModalVisible(true);
        } else {
          setPriceOption(price);
          setIsPriceOptionConfirmModalVisible(true);
        }
      }, 350);
    }
  };

  const getSimplepayUrl = async (amount: number) => {
    const simplepayResult = await startSimplepayTransaction(amount);
    if (simplepayResult?.paymentUrl) return simplepayResult.paymentUrl;
    else return null;
  };

  const priceOptionConfirmHandler = async (confirm?: boolean) => {
    setIsPriceOptionConfirmModalVisible(false);
    if (confirm) {
      if (typeof priceOption === 'number') {
        const simplepayUrl = await getSimplepayUrl(priceOption);
        if (simplepayUrl) {
          paymentContext.setPaymentType(ESimplePayCallbackTypes.PENDING);
          window.open(simplepayUrl);
        }
      }
    }
  };

  const getWalletData = async () => {
    const data = await getCreditInformation();

    if (data?.status?.code > 0) {
      setSnack({
        isError: true,
        open: true,
        message: data.status.message
      });
      setCreditInformation('N/A');
      return;
    }

    const balance = new Intl.NumberFormat('hu-HU', {
      style: 'currency',
      currency: 'HUF',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }).format(data);
    setCreditInformation(balance);
  };

  const autoRefreshWalletData = () => {
    setTimeout(() => {
      getWalletData();
      autoRefreshWalletData();
    }, 60 * 1000);
  };

  useEffect(() => {
    getWalletData();
    let timer = setTimeout(() => {
      autoRefreshWalletData();
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const paymentPending = searchParams.get('paymentPending');
    if (paymentPending === 'true') {
      window.close();
    }
  }, []);

  return (
    <>
      <div className="home-container">
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <div className="card personcredit-information">
              <div className="credit">{creditInformation}</div>
              <div className="text">{t('home.balanceText')}</div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="card upload-money" onClick={() => setIsPriceOptionsModalVisible(true)}>
              <div className="icon">
                <TopUpIcon />
              </div>
              <div className="text">{t('home.uploadBalance')}</div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Link className="link" to={'/transaction-history'}>
              <div className="card transaction-history">
                <div className="icon">
                  <SyncIcon />
                </div>
                <div className="text">{t('home.transactionHistory')}</div>
              </div>
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Link className="link" to={'/menu'}>
              <div className="card menu">
                <div className="icon">
                  <FoodMenuIcon />
                </div>
                <div className="text">{t('home.menu')}</div>
              </div>
            </Link>
          </Grid>
        </Grid>
      </div>
      <PaymentModalRoot />
      <PriceOptionsModal
        isVisible={isPriceOptionsModalVisible}
        closeModalHandler={priceOptionSelectedHandler}
      />
      <PriceOptionConfirmModal
        isVisible={isPriceOptionConfirmModalVisible}
        closeModalHandler={priceOptionConfirmHandler}
        topUpBy={priceOption}
      />
      <CustomPriceModal
        isVisible={isCustomPriceModalVisible}
        closeModalHandler={priceOptionSelectedHandler}
      />
    </>
  );
};

export default HomePage;
