import './PaymentPendingModal.scss';

import { Grid } from '@mui/material';
import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PaymentFailedIcon } from '../../../assets/icons/payment-failed-icon.svg';
import CustomButton from '../../UI/CustomButton/CustomButton';
import Modal from '../../UI/Modal/Modal';

interface PaymentPendingModalProps {
  isVisible: boolean;
  closeModalHandler: () => void;
}

const PaymentPendingModal: FunctionComponent<PaymentPendingModalProps> = memo(
  ({ isVisible, closeModalHandler }) => {
    const { t } = useTranslation();

    const handleConfirm = () => {
      closeModalHandler();
    };

    return (
      <Modal
        isVisible={isVisible}
        closeModalHandler={closeModalHandler}
        customClass="payment-pending-modal">
        <Grid container direction="column" spacing={2} className="payment-pending-modal-content">
          <Grid item className="modal-container">
            <PaymentFailedIcon />
            <p className="modal-header-text font-audi-extended-bold">
              {t('modals.pendingTransactionHeader')}
            </p>
            <p className="modal-small-text font-audi-normal">
              {t('modals.pendingTransactionText')}
            </p>
            <CustomButton customFunction={handleConfirm} text={t('header.home')} />
          </Grid>
        </Grid>
      </Modal>
    );
  }
);

export default memo(PaymentPendingModal);
