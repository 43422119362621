import { AxiosResponse } from 'axios';
import jwt_decode from 'jwt-decode';
import moment from 'moment';

import { ApiClient } from '../apiClient/apiClient';
import { ILoginRequest } from '../interfaces/requests/auth/login.request';
import { IJWTPayload } from './../interfaces/models/jwt.payload.model';

const api = ApiClient.getInstance();

const login = async (loginRequest: ILoginRequest) => {
  const response = await api
    .post('auth/login', { ...loginRequest, platform: 'Webapp' })
    .then((res) => {
      localStorage.setItem('accessToken', res.data.accessToken);
      let expiresAt = moment().add(14, 'm').toISOString();
      localStorage.setItem('expiresAt', expiresAt);
      localStorage.setItem('termsAcceptedAt', moment().toISOString());
      localStorage.setItem('userId', loginRequest.personCode);
    })
    .catch((error) => {
      return error.response.data.message;
    });

  return response;
};

const refreshToken = async () => {
  const token = localStorage.getItem('accessToken');

  let payload: IJWTPayload = jwt_decode(token!);
  const params = { userId: payload.userId, tokenUUID: payload.tokenUUID, platform: 'Webapp' };

  const response = await api.post('auth/refreshToken', params);
  return response;
};

const revokeToken = async () => {
  const token = localStorage.getItem('accessToken');

  let payload: IJWTPayload = jwt_decode(token!);

  const params = { tokenUUID: payload.tokenUUID, platform: 'Webapp' };
  const response = await api.post('auth/revokeToken', params);
  return response;
};

const isUserLoggedIn = async (): Promise<boolean> => {
  const token = localStorage.getItem('accessToken');

  if (token) {
    return Promise.resolve(true);
  }

  return Promise.resolve(false);
};

const deleteUser = async (): Promise<AxiosResponse<any>> => {
  const response = await api.delete(`users/delete?platform=Webapp`);

  return response;
};

export { login, revokeToken, isUserLoggedIn, refreshToken, deleteUser };
