import './App.css';

import { createContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import io, { Socket } from 'socket.io-client';

import SnackbarAlert, { ISnackbarProps, SnackbarContext } from './components/SnackbarAlert/SnackbarAlert';
import { ESimplePayCallbackTypes } from './enums/simplepay-callback-types.enum';
import Router from './routes/Router';
import { isUserLoggedIn } from './services/auth.service';

export const LoginContext = createContext({
  isLoggedIn: false,
  setIsLoggedIn: (isLoggedIn: boolean) => {}
});

export const PaymentContext = createContext({
  paymentType: ESimplePayCallbackTypes.NONE,
  setPaymentType: (paymentType: ESimplePayCallbackTypes) => {},
  transactionCode: '',
  setTransactionCode: (transactionCode: string) => {}
});

function App() {
  let socket: Socket | null = null;

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [snack, setSnack] = useState<ISnackbarProps>({
    open: false,
    message: '',
    isError: true
  });
  const [paymentType, setPaymentType] = useState<ESimplePayCallbackTypes>(
    ESimplePayCallbackTypes.NONE
  );
  const [transactionCode, setTransactionCode] = useState<string>('');

  useEffect(() => {
    isUserLoggedIn().then((value: boolean) => {
      return value ? setIsLoggedIn(true) : setIsLoggedIn(false);
    });
  }, [isLoggedIn]);

  const [userId, setUserId] = useState<string | null>(null);
  const [isWsConnecting, setisWsConnecting] = useState(false);

  const declareWsMethods = (userId: string) => {
    const URL = process.env.REACT_APP_API_BASE_URL
      ? process.env.REACT_APP_API_BASE_URL.split('/api')[0]
      : '';

    socket = io("https://tui-gastro-api.audi.hu/", {
      secure: true,
      path: '/socket.io',
      extraHeaders: {
        userId
      }
    });

    socket?.on('connect', () => {
      console.log('Connected to server');
    });

    socket?.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    socket?.on('connect_error', (error: any) => {
      console.log('Connection error: ' + error.message);
    });

    socket?.on('transaction', (e) => {
      console.log('Transaction received: ' + e);

      const transactionData = JSON.parse(e);

      setTransactionCode(transactionData.transactionId);
      setPaymentType(transactionData.status);
    });
  };

  useEffect(() => {
    const id = localStorage.getItem('userId');
    setUserId(id);
  }, []);

  const connectSocket = () => {
    if (userId) {
      declareWsMethods(userId);
    }
    setisWsConnecting(false);
  };

  useEffect(() => {
    if (isWsConnecting) return;
    setisWsConnecting(true);
    !socket?.connected && connectSocket();
  }, [userId]);

  return (
    <LoginContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      <PaymentContext.Provider
        value={{ paymentType, setPaymentType, transactionCode, setTransactionCode }}>
        <SnackbarContext.Provider value={{ snack, setSnack }}>
          <div className="font-audi-normal">
            <BrowserRouter>
              <Router isLoggedIn={isLoggedIn} />
            </BrowserRouter>
          </div>
          <SnackbarAlert></SnackbarAlert>
        </SnackbarContext.Provider>
      </PaymentContext.Provider>
    </LoginContext.Provider>
  );
}

export default App;
